.main-title {
  color: #870404;
}

.sub-title {
  border-bottom: 5px dotted #870404;
  padding-bottom: 5px;
}

.telcomColor {
  color: #870404;
}

.text-apply {
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: #870404;
  padding: 65px;
  border-radius: 100px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
}

/* Section Form Design */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

.application-form {
  display: flex;
  flex-direction: column;
}

.application-form label {
  margin-bottom: 15px;
}

.open-modal-button {
  padding: 10px 20px;
  background-color: #870404;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.open-modal-button:hover {
  background-color: #b12a2a;
}
